import React from 'react';
import { Typography } from '@material-ui/core';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';

const theme = createTheme({
  palette: {
      text: {
          primary: "#000000",
          secondary: "#ffffff"
    },
    primary: {
      main: '#FFD300'
    }
  },
  typography: {
    body1: {
      lineHeight: 'inherit'
    },
    fontFamily: [
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
  },
  overrides: {
   MuiFormLabel: {
     root: {
       color: '#000000',
       "&$focused": {
        color: "#000000"
      }
     },
   },
   MuiInput: {
    underline: {
      '&:after': {
         borderBottom: '2px solid #000000'
      }
    },
   }
  }
});

const Provider = ({ element }) => (
  <ThemeProvider theme={theme}>
    <Typography component='main' style={{ lineHeight: 'inherit' }}>
      {element}
    </Typography>
  </ThemeProvider>
);

export default Provider;
