/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import './src/global.css';
import muiRootWrapper from "./src/theme";
import 'typeface-roboto';


export const wrapRootElement = muiRootWrapper;
